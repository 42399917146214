exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-avalanche-js": () => import("./../../../src/pages/avalanche.js" /* webpackChunkName: "component---src-pages-avalanche-js" */),
  "component---src-pages-bitcoin-cash-js": () => import("./../../../src/pages/bitcoin-cash.js" /* webpackChunkName: "component---src-pages-bitcoin-cash-js" */),
  "component---src-pages-bitcoin-js": () => import("./../../../src/pages/bitcoin.js" /* webpackChunkName: "component---src-pages-bitcoin-js" */),
  "component---src-pages-bnb-js": () => import("./../../../src/pages/bnb.js" /* webpackChunkName: "component---src-pages-bnb-js" */),
  "component---src-pages-compound-js": () => import("./../../../src/pages/compound.js" /* webpackChunkName: "component---src-pages-compound-js" */),
  "component---src-pages-decrypt-js": () => import("./../../../src/pages/decrypt.js" /* webpackChunkName: "component---src-pages-decrypt-js" */),
  "component---src-pages-ethereum-js": () => import("./../../../src/pages/ethereum.js" /* webpackChunkName: "component---src-pages-ethereum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-polygon-js": () => import("./../../../src/pages/polygon.js" /* webpackChunkName: "component---src-pages-polygon-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sushi-swap-js": () => import("./../../../src/pages/sushi-swap.js" /* webpackChunkName: "component---src-pages-sushi-swap-js" */),
  "component---src-pages-tether-js": () => import("./../../../src/pages/tether.js" /* webpackChunkName: "component---src-pages-tether-js" */),
  "component---src-pages-uni-swap-js": () => import("./../../../src/pages/uni-swap.js" /* webpackChunkName: "component---src-pages-uni-swap-js" */),
  "component---src-pages-usd-coin-js": () => import("./../../../src/pages/usd-coin.js" /* webpackChunkName: "component---src-pages-usd-coin-js" */),
  "component---src-pages-verse-js": () => import("./../../../src/pages/verse.js" /* webpackChunkName: "component---src-pages-verse-js" */),
  "component---src-pages-wc-js": () => import("./../../../src/pages/wc.js" /* webpackChunkName: "component---src-pages-wc-js" */),
  "component---src-pages-wrapped-btc-js": () => import("./../../../src/pages/wrapped-btc.js" /* webpackChunkName: "component---src-pages-wrapped-btc-js" */),
  "component---src-pages-yearn-finance-js": () => import("./../../../src/pages/yearn-finance.js" /* webpackChunkName: "component---src-pages-yearn-finance-js" */),
  "component---src-pages-zano-js": () => import("./../../../src/pages/zano.js" /* webpackChunkName: "component---src-pages-zano-js" */)
}

